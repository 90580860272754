import React, { useState, useEffect, useMemo } from 'react'

import ClaroPayContext from '../../context/ClaroPayContext'

import {
  clearLocalStorage,
  setLocalStorage,
  getLocalStorage,
} from '../../services/storage'
import { getAxiosService } from '../../services/getAxiosService'
import { postAxiosService } from '../../services/postAxiosService'

import BACK_END_ROUTES from '../../constants/api_backend_url_supplier'
import elementNameCodeMap from '../../constants/Metrics'
import { RESPONSE_CODE } from '../../constants/responseCode'
import localRoutes from '../../constants/routes'
import {
  MENU_REDIRECT,
  PRODUCT_SELECTED_HOME,
  MENU_CATEGORY,
  MENU_CODE,
  METRICS_SESSION,
  PAYMENT_PROCESS,
  AUTH_TOKENS_HOME,
  AUTH_TOKENS_REFRESH_HOME,
  MSISDN_HOME,
  MENU_OPTION_SUPERPACKS,
  MENU_OPTION_RECARGAS,
  MENU_OPTION_SUPERPACKS_AND_RECARGAS,
  MENU_OPTION_ADM,
  MENU_OPTION_PAGARDEUDA,
  MENU_OPTION_ENVIARECARGA,
  MENU_TYPE_VERTICAL,
  MENU_OPTION_CERRARSESION,
} from '../../constants/ClaroPayConstants'

import { encryptData } from '../../components/EncryptData'
import { getImage } from '../../components/GetImage'
import { DeepLinkBi } from '../../components/DeepLinkBi'
import handleRedirectHome from '../../components/HandleRedirectHome'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import LoadingScreen from '../../components/LoadingScreen'
import Menu from '../../components/Menu'
import { clearStorageLandings } from '../../components/ClearStorageLandings'
import ProcessPaymentResponse from '../../components/ProcessPaymentResponse'

const ClaroHomeLayout = ({ children }) => {
  const {
    routesClaroPay: {
      claroAdm,
      claroSendARecharge,
      claroPagaTuPrestamo,
      claroHome,
      claroHomeAuth,
    },
  } = localRoutes

  const { processPaymentResponse } = ProcessPaymentResponse()

  const { FAIL_SERVER_CLARO_PAY } = RESPONSE_CODE

  const [products, setProducts] = useState(null)
  const [homeData, setHomeData] = useState()
  const [alias, setAlias] = useState([])
  const [indicator, setIndicator] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [homeProduct, setHomeProduct] = useState({})
  const [responseService, setResponseService] = useState(null)
  const [responseServiceBilling, setResponseServiceBilling] = useState(null)
  const [processingBilling, setProcessingBilling] = useState(false)
  const [selectedSavedCard, setSelectedSavedCard] = useState({})
  const [
    isAcceptedTermsAndConditions,
    setIsAcceptedTermsAndConditions,
  ] = useState(true)
  const [isVisibleSectionAddNewCard, setIsVisibleSectionAddNewCard] = useState(
    false,
  )
  const [
    isVisibleAddNewPaymentMethod,
    setIsVisibleAddNewPaymentMethod,
  ] = useState(false)
  const [iIsVisibleAddNewAccountBi, setIsVisibleAddNewAccountBi] = useState(
    false,
  )
  const [selectAccountBi, setSelectAccountBi] = useState(null)
  const [saveCard, setSaveCard] = useState(false)
  const [year, setYear] = useState('')
  const [month, setMonth] = useState('')
  const [saveNewCard, setSaveNewCard] = useState(true)
  const [yearNewCard, setYearNewCard] = useState('')
  const [monthNewCard, setMonthNewCard] = useState('')
  const [cvv, setCvv] = useState('')
  const [numberCard, setNumberCard] = useState('')
  const [maxLengthCvv, setMaxLengthCvv] = useState(3)
  const [maxLengthNumberCard, setMaxLengthNumberCard] = useState(16)
  const [processingPayment, setProcessingPayment] = useState(false)
  const [isEnabledPayWithSavedCard, setIsEnabledPayWithSavedCard] = useState(
    false,
  )
  const [isEnabledPayWithNewCard, setIsEnabledPayWithNewCard] = useState(false)
  const [requestId, setRequestId] = useState(null)
  const [urlAccountBi, setUrlAccountBi] = useState('')
  const [urltiendaApps, setUrltiendaApps] = useState('')
  const [currentCategoryMetrics, setCurrentCategoryMetrics] = useState(
    'Recarga-',
  )
  const [canClickButton, setCanClickButton] = useState(true)
  const [menuVisible, setMenuVisible] = useState(false)
  const [opcionSeleccionada, setOpcionSeleccionada] = useState(null)
  const [codeBilling, setCodeBilling] = useState(null)
  const [responsePackRecurrence, setResponsePackRecurrence] = useState({})

  useEffect(() => {
    if (isLoading) {
      const metricsData = {
        metricType: 'Proceso_OtrasOpciones',
        metricName: 'Proceso_OtrasOpciones',
      }
      sendMetrics(metricsData)
    }
  }, [isLoading])

  const setDefaultValues = (obj, key, defaultValue) => {
    if (
      typeof obj[key] === 'undefined' ||
      (typeof obj[key] !== 'object' && !obj[key])
    ) {
      obj[key] = defaultValue
    }
  }

  const getCard = async () => {
    setIsLoading(true)
    try {
      const Authorization =
        getLocalStorage(AUTH_TOKENS_HOME) !== undefined &&
        getLocalStorage(AUTH_TOKENS_HOME) !== null
          ? { Authorization: `Bearer ${getLocalStorage(AUTH_TOKENS_HOME)}` }
          : {}
      const url = BACK_END_ROUTES.homeGetCard
      const headers = { ...Authorization }
      const response = await getAxiosService(url, { headers })
      const { responseContent } = response

      setDefaultValues(responseContent, 'creditCardList', [])
      setDefaultValues(responseContent, 'alias', [])
      setDefaultValues(responseContent, 'indicator', false)

      setHomeData(responseContent)
      setAlias(responseContent.alias)
      setIndicator(responseContent.indicator)
    } catch (error) {
      resetContext()
    }
    setIsLoading(false)
  }

  const tokenValid = async () => {
    try {
      clearLocalStorage(MENU_CODE)
      const Authorization =
        getLocalStorage(AUTH_TOKENS_REFRESH_HOME) !== undefined &&
        getLocalStorage(AUTH_TOKENS_REFRESH_HOME) !== null
          ? {
              Authorization: `Bearer ${getLocalStorage(
                AUTH_TOKENS_REFRESH_HOME,
              )}`,
            }
          : {}
      const url = BACK_END_ROUTES.homeAccessToken
      const headers = { ...Authorization }
      const response = await getAxiosService(url, { headers })
      const { responseContent } = response
      setLocalStorage(AUTH_TOKENS_HOME, responseContent.token)
      setLocalStorage(MENU_CODE, responseContent.code)
    } catch (error) {
      const metricsData = {
        metricType: 'Login_Token',
        metricName: 'Login_Token-Error',
      }
      await sendMetrics(metricsData)
      resetContext()
    }
  }

  const getProducts = async () => {
    clearStorageLandings()
    try {
      let packRecurrence = {}
      if (isAuthenticated) {
        if (Object.keys(responsePackRecurrence).length === 0) {
          packRecurrence = await getPackRecurrence()
          setResponsePackRecurrence(packRecurrence)
        } else {
          packRecurrence = responsePackRecurrence
        }
      }
      if (!products) {
        setIsLoading(true)
        const url = BACK_END_ROUTES.homeProduct
        const response = await getAxiosService(url)
        const { responseContent } = response
        const updatedResponseContent = {
          ...responseContent,
          packRecurrence,
        }
        setProducts(updatedResponseContent)
        setIsLoading(false)
        return { productsData: updatedResponseContent, calledService: true }
      } else {
        const updatedResponseContent = {
          ...products,
          packRecurrence,
        }
        return { productsData: updatedResponseContent, calledService: false }
      }
    } catch (error) {
      resetContext(true)
    }
  }

  const getPackRecurrence = async () => {
    try {
      const Authorization =
        getLocalStorage(AUTH_TOKENS_HOME) !== undefined &&
        getLocalStorage(AUTH_TOKENS_HOME) !== null
          ? { Authorization: `Bearer ${getLocalStorage(AUTH_TOKENS_HOME)}` }
          : {}
      const url = BACK_END_ROUTES.recurrenceLatest
      const headers = { ...Authorization }
      const response = await getAxiosService(url, { headers })
      const { responseContent } = response
      const visible = responseContent.visible
      if (visible) {
        return responseContent
      } else {
        return {}
      }
    } catch (error) {
      return {}
    }
  }

  const resetContext = value => {
    resetToken()

    if (value) {
      window.location.href = window.location.origin + claroHomeAuth
    } else {
      handleRedirectHome()
    }
  }

  const exit = async () => {
    clearLocalStorage(MENU_CATEGORY)
    const metricsData = {
      metricType: 'CheckOut_',
      metricName: 'CerrarSesión',
    }
    await sendMetrics(metricsData)
    resetContext(true)
  }

  const isValidatedFieldsToPayWithSavedCard = () =>
    year !== '' &&
    Number(year) > 0 &&
    year.length === 2 &&
    month !== '' &&
    Number(month) > 0 &&
    isAcceptedTermsAndConditions === true

  const isValidatedFieldsToPayWithNewCard = () =>
    yearNewCard !== '' &&
    Number(yearNewCard) > 0 &&
    yearNewCard.length === 2 &&
    monthNewCard !== '' &&
    Number(monthNewCard) > 0 &&
    cvv !== '' &&
    Number(cvv) > 0 &&
    cvv.length === maxLengthCvv &&
    numberCard !== '' &&
    Number(numberCard) > 0 &&
    numberCard.length === maxLengthNumberCard &&
    isAcceptedTermsAndConditions === true

  useEffect(() => {
    setIsEnabledPayWithSavedCard(isValidatedFieldsToPayWithSavedCard())
  }, [year, month, isAcceptedTermsAndConditions])

  useEffect(() => {
    setIsEnabledPayWithNewCard(isValidatedFieldsToPayWithNewCard())
  }, [yearNewCard, monthNewCard, cvv, numberCard, isAcceptedTermsAndConditions])

  const clearData = () => {
    setSaveCard(false)
    setYear('')
    setMonth('')
    setSaveNewCard(true)
    setYearNewCard('')
    setMonthNewCard('')
    setCvv('')
    setNumberCard('')
    setSelectedSavedCard({})
    setIsVisibleSectionAddNewCard(false)
    setIsVisibleAddNewPaymentMethod(false)
    setIsVisibleAddNewAccountBi(false)
    setSelectAccountBi(null)
  }

  const handleRedirect = () => {
    return claroHome
  }

  const responseErrorDefault = async () => {
    const response = { responseCode: FAIL_SERVER_CLARO_PAY.code }
    return await processPaymentResponse(
      response,
      handleRedirect,
      clearData,
      setCodeBilling,
    )
  }

  const paymentCard = async uuid => {
    let data = {}

    if (isVisibleSectionAddNewCard) {
      if (isValidatedFieldsToPayWithNewCard()) {
        const numberCardEncrypted = await encryptData(numberCard)
        const cvvEncrypted = await encryptData(cvv)
        const expirationDateEncrypted = await encryptData(
          `20${yearNewCard}-${monthNewCard.padStart(2, '0')}`,
        )
        data = {
          productId: homeProduct.productId,
          creditCard: {
            cardHolder: '',
            number: numberCardEncrypted,
            cvv: cvvEncrypted,
            expirationDate: expirationDateEncrypted,
          },
          conditionsAccepted: isAcceptedTermsAndConditions,
          saveCard: saveNewCard,
          transactionId: uuid,
        }
      }
    } else if (isValidatedFieldsToPayWithSavedCard()) {
      const expirationDateSavedEncrypted = await encryptData(
        `20${year}-${month.padStart(2, '0')}`,
      )
      data = {
        productId: homeProduct.productId,
        creditCard: {
          cardHolder: '',
          token: selectedSavedCard.tokenId,
          expirationDate: expirationDateSavedEncrypted,
        },
        conditionsAccepted: isAcceptedTermsAndConditions,
        saveCard: saveCard,
        transactionId: uuid,
      }
    }

    try {
      const Authorization = getLocalStorage(AUTH_TOKENS_HOME)
        ? { Authorization: `Bearer ${getLocalStorage(AUTH_TOKENS_HOME)}` }
        : {}
      const url = BACK_END_ROUTES.homePaymentWithCard
      const headers = { ...Authorization }
      const response = await postAxiosService(url, data, { headers })
      return await processPaymentResponse(
        response,
        handleRedirect,
        clearData,
        setCodeBilling,
      )
    } catch (error) {
      return await responseErrorDefault()
    }
  }

  const payWithAccountBi = async uuid => {
    try {
      let data = {
        productId: homeProduct.productId,
        account: selectAccountBi,
        conditionsAccepted: isAcceptedTermsAndConditions,
        transactionId: uuid,
      }
      const Authorization =
        getLocalStorage(AUTH_TOKENS_HOME) !== undefined &&
        getLocalStorage(AUTH_TOKENS_HOME) !== null
          ? { Authorization: `Bearer ${getLocalStorage(AUTH_TOKENS_HOME)}` }
          : {}
      const url = BACK_END_ROUTES.homePaymentWithAccountBi
      const headers = { ...Authorization }
      const response = await postAxiosService(url, data, { headers })
      return await processPaymentResponse(
        response,
        handleRedirect,
        clearData,
        setCodeBilling,
      )
    } catch (error) {
      return await responseErrorDefault()
    }
  }

  const payWithNewAccountBi = async productId => {
    setLocalStorage(PAYMENT_PROCESS, handleRedirect())
    try {
      let urlBackend = BACK_END_ROUTES.homeUuidAccountBi
      let urlBackendCompleta = `${urlBackend}?productId=${productId}`
      const Authorization =
        getLocalStorage(AUTH_TOKENS_HOME) !== undefined &&
        getLocalStorage(AUTH_TOKENS_HOME) !== null
          ? { Authorization: `Bearer ${getLocalStorage(AUTH_TOKENS_HOME)}` }
          : {}
      const headers = { ...Authorization }
      const response = await getAxiosService(urlBackendCompleta, { headers })
      const { responseContent } = response
      const responseRequestId = `requestId=${responseContent}`
      setRequestId(responseRequestId)
      const { deeplink, appStoreUrl } = DeepLinkBi(responseRequestId)
      setUrlAccountBi(deeplink)
      setUrltiendaApps(appStoreUrl)
    } catch (error) {
      resetContext()
      clearLocalStorage(METRICS_SESSION)
    }
  }

  const resetToken = () => {
    clearLocalStorage(AUTH_TOKENS_HOME)
    clearLocalStorage(AUTH_TOKENS_REFRESH_HOME)
    clearLocalStorage(MSISDN_HOME)
    clearLocalStorage(MENU_CODE)
  }

  const isTokenValid = token => {
    if (token) {
      const tokenParts = token.split('.')

      if (tokenParts.length === 3) {
        const encodedPayload = tokenParts[1]
        const decodedPayload = atob(encodedPayload)
        try {
          const payload = JSON.parse(decodedPayload)
          return payload.exp * 1000 > Date.now()
        } catch (error) {}
      }
    }

    return false
  }

  const authGuard = () => {
    const authTokens = getLocalStorage(AUTH_TOKENS_HOME)
    const refreshTokenHome = getLocalStorage(AUTH_TOKENS_REFRESH_HOME)
    const isValid =
      authTokens !== undefined &&
      authTokens !== null &&
      isTokenValid(refreshTokenHome)

    if (!isValid) {
      resetToken()
    }

    return isValid
  }

  const processMetrics = metricsData => {
    const { metricType, metricName } = metricsData
    const msisdnMetricsLocalStorage = getLocalStorage(MSISDN_HOME)

    let elementLocationMetrics = 'HOME'
    let elementLocationCodeMetrics = 'HOM'
    let msisdnMetrics = msisdnMetricsLocalStorage ?? '-'
    let elementNameMetrics = ''

    if (metricType === 'CheckOut_') {
      elementNameMetrics = metricType + currentCategoryMetrics + metricName
    } else if (metricType === 'PagoExitoso') {
      elementNameMetrics =
        metricName +
        (currentCategoryMetrics === 'Recarga-' ? 'OkRecarga' : 'OkPaquete')
    } else if (metricType === 'ExitosoFel') {
      elementNameMetrics =
        (currentCategoryMetrics === 'Recarga-' ? 'ExitosoR_' : 'ExitosoP_') +
        metricName
    } else {
      elementNameMetrics = metricName
    }
    return {
      elementLocationMetrics,
      elementLocationCodeMetrics,
      elementNameMetrics,
      msisdnMetrics,
    }
  }

  const sendMetrics = async metricsData => {
    const {
      elementLocationMetrics,
      elementLocationCodeMetrics,
      elementNameMetrics,
      msisdnMetrics,
    } = processMetrics(metricsData)

    const elementNameCodeMetrics =
      elementNameCodeMap[elementNameMetrics] || 'CódigoNoEncontrado'
    try {
      const data = {
        elementLocation: elementLocationMetrics,
        elementLocationCode: elementLocationCodeMetrics,
        elementName: elementNameMetrics,
        elementNameCode: elementNameCodeMetrics,
        msisdn: msisdnMetrics,
        code: 'Home',
      }
      const url = BACK_END_ROUTES.registerMetrics
      await postAxiosService(url, data)
      if (
        elementNameCodeMetrics === '137' ||
        elementNameCodeMetrics === '138'
      ) {
        setLocalStorage(METRICS_SESSION, JSON.stringify(data))
      }
    } catch (error) {}
  }

  const toggleMenu = () => {
    if (!menuVisible) {
      const metricsData = {
        metricType: 'Menu',
        metricName: 'Menu_DespliegueMenuPrincipal',
      }
      sendMetrics(metricsData)
    }
    setMenuVisible(!menuVisible)
  }

  const menuRoutes = {
    [MENU_OPTION_ADM]: claroAdm,
    [MENU_OPTION_PAGARDEUDA]: claroPagaTuPrestamo,
    [MENU_OPTION_ENVIARECARGA]: claroSendARecharge,
  }

  const getMetricsData = (type, opcion) => ({
    metricType: 'Menu',
    metricName:
      type === MENU_TYPE_VERTICAL ? `Menu_${opcion}` : `CintaGris_${opcion}`,
  })

  const isAuthenticated = authGuard()

  const redirectMenu = async menuType => {
    if (isAuthenticated) {
      let menuCode = null
      menuCode = getLocalStorage(MENU_CODE)
      if (!menuCode) {
        setIsLoading(true)
        await tokenValid()
        menuCode = getLocalStorage(MENU_CODE)
      }
      clearLocalStorage(MENU_REDIRECT)
      const urlLanding = menuRoutes[menuType] || ''
      const url = `${urlLanding}?code=${menuCode}`
      window.location.href = `${window.location.origin}${url}`
    } else {
      setLocalStorage(MENU_REDIRECT, menuType)
      window.location.href = window.location.origin
    }
  }

  const handleOptionClick = async (opcion, type) => {
    if (opcionSeleccionada !== opcion) {
      if (opcion !== MENU_OPTION_CERRARSESION) {
        setLocalStorage(MENU_CATEGORY, opcion)
        setOpcionSeleccionada(opcion)
      }

      const metricsData = getMetricsData(type, opcion)
      await sendMetrics(metricsData)
      clearLocalStorage(PRODUCT_SELECTED_HOME)
      clearLocalStorage(MENU_REDIRECT)

      const actionMap = {
        [MENU_OPTION_SUPERPACKS]: claroHome,
        [MENU_OPTION_RECARGAS]: claroHome,
        [MENU_OPTION_SUPERPACKS_AND_RECARGAS]: claroHome,
        [MENU_OPTION_ADM]: () => redirectMenu(MENU_OPTION_ADM),
        [MENU_OPTION_PAGARDEUDA]: () => redirectMenu(MENU_OPTION_PAGARDEUDA),
        [MENU_OPTION_ENVIARECARGA]: () =>
          redirectMenu(MENU_OPTION_ENVIARECARGA),
        [MENU_OPTION_CERRARSESION]: exit,
      }

      const action = actionMap[opcion]
      if (typeof action === 'function') {
        action()
      } else {
        window.location.href = `${window.location.origin}${action}`
      }
    }
  }

  const msisdn = getLocalStorage(MSISDN_HOME)
  const msisdnHeader = isAuthenticated ? msisdn.slice(3) : ''

  const dependencies = {
    paymentCard,
    payWithAccountBi,
    payWithNewAccountBi,
    products,
    setProducts,
    homeData,
    setHomeData,
    isLoading,
    setIsLoading,
    homeProduct,
    setHomeProduct,
    tokenValid,
    getProducts,
    getCard,
    isAcceptedTermsAndConditions,
    setIsAcceptedTermsAndConditions,
    selectedSavedCard,
    setSelectedSavedCard,
    setSaveCard,
    year,
    setYear,
    month,
    setMonth,
    saveNewCard,
    setSaveNewCard,
    yearNewCard,
    setYearNewCard,
    monthNewCard,
    setMonthNewCard,
    cvv,
    setCvv,
    numberCard,
    setNumberCard,
    processingPayment,
    setProcessingPayment,
    responseService,
    setResponseService,
    isVisibleSectionAddNewCard,
    setIsVisibleSectionAddNewCard,
    isVisibleAddNewPaymentMethod,
    setIsVisibleAddNewPaymentMethod,
    iIsVisibleAddNewAccountBi,
    setIsVisibleAddNewAccountBi,
    selectAccountBi,
    setSelectAccountBi,
    maxLengthCvv,
    setMaxLengthCvv,
    maxLengthNumberCard,
    setMaxLengthNumberCard,
    isValidatedFieldsToPayWithSavedCard,
    isValidatedFieldsToPayWithNewCard,
    isEnabledPayWithSavedCard,
    isEnabledPayWithNewCard,
    setIsEnabledPayWithSavedCard,
    requestId,
    setRequestId,
    urlAccountBi,
    setUrlAccountBi,
    urltiendaApps,
    setUrltiendaApps,
    isAuthenticated,
    clearData,
    currentCategoryMetrics,
    setCurrentCategoryMetrics,
    sendMetrics,
    canClickButton,
    setCanClickButton,
    setOpcionSeleccionada,
    alias,
    indicator,
    redirectMenu,
    getImage,
    responseServiceBilling,
    setResponseServiceBilling,
    processingBilling,
    setProcessingBilling,
    codeBilling,
    setCodeBilling,
  }

  const contextValue = useMemo(
    () => ({
      ...dependencies,
    }),
    Object.values(dependencies),
  )

  return (
    <ClaroPayContext.Provider value={contextValue}>
      <div className="claro">
        <Header mssidn={msisdnHeader} toggleMenu={toggleMenu} />

        <Menu
          opcionSeleccionada={opcionSeleccionada}
          toggleMenu={toggleMenu}
          handleOptionClick={handleOptionClick}
          isAuthenticated={isAuthenticated}
          menuVisible={menuVisible}
        />

        <div className="claro__container">
          {isLoading && !processingPayment && <LoadingScreen />}

          {children}
        </div>

        <Footer />
      </div>
    </ClaroPayContext.Provider>
  )
}
export default ClaroHomeLayout
