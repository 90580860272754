import React, { useContext, useEffect, useState } from 'react'

import ClaroPayContext from '../../context/ClaroPayContext'

import { clearLocalStorage, getLocalStorage } from '../../services/storage'

import SavedCard from '../../components/SavedCard'
import NewCard from '../../components/NewCard'
import SavedAccount from '../../components/SavedAccount'

import {
  labels,
  PROCESO_PAGO,
  METRICS_SESSION,
  PAYMENT_PROCESS,
} from '../../constants/ClaroPayConstants'

import circleAccepted from '../../assets/img/circle_accepted.svg'
import circleNotAccepted from '../../assets/img/circle_not_accepted.svg'
import logoBi from '../../assets/img/logoBiAzul.png'
import arrow from '../../assets/img/arrow.svg'

const ClaroPayPaymentAccountBi = () => {
  const claroPayContext = useContext(ClaroPayContext)

  const {
    ADDNEWCARD,
    NEWPAYMENTMETHOD,
    BANK_ACCOUNT,
    VIEW_OTHER_PAYMENT_OPTIONS,
    PAYMENT_TITLE,
  } = labels

  const {
    recurrenceData,
    selectedSavedCard,
    setSelectedSavedCard,
    setSaveCard,
    setYear,
    setMonth,
    setSaveNewCard,
    setYearNewCard,
    setMonthNewCard,
    setCvv,
    numberCard,
    setNumberCard,
    isVisibleSectionAddNewCard,
    setIsVisibleSectionAddNewCard,
    isVisibleAddNewPaymentMethod,
    setIsVisibleAddNewPaymentMethod,
    iIsVisibleAddNewAccountBi,
    setIsVisibleAddNewAccountBi,
    selectAccountBi,
    setSelectAccountBi,
    setMaxLengthCvv,
    setMaxLengthNumberCard,
    callMeData,
    bnplData,
    pagaTuPrestamoData,
    minecoBecasData,
    cbkUssdData,
    homeData,
    sendARechargeData,
    sendMetrics,
    code,
    setProcessingPayment,
    processingPayment,
    setResponseService,
    responseService,
    setCanClickButton,
    demoBiData,
    setCodeBilling,
  } = claroPayContext

  useEffect(() => {
    setProcessingPayment(false)
    setResponseService(null)
    clearLocalStorage(METRICS_SESSION)
    clearLocalStorage(PAYMENT_PROCESS)
    setCodeBilling(null)
    const procesoPago = getLocalStorage(PROCESO_PAGO)
    if (procesoPago) {
      setCanClickButton(false)
      const timeoutId = setTimeout(() => {
        setCanClickButton(true)
        clearLocalStorage(PROCESO_PAGO)
      }, 30000)
      return () => clearTimeout(timeoutId)
    } else {
      setCanClickButton(true)
    }
  }, [responseService, processingPayment])

  let creditCardList = []
  let alias = []
  let indicator = false

  const dataSources = [
    bnplData,
    pagaTuPrestamoData,
    minecoBecasData,
    cbkUssdData,
    callMeData,
    recurrenceData,
    sendARechargeData,
    demoBiData,
  ]

  for (const source of dataSources) {
    if (source?.creditCardList) {
      creditCardList = source.creditCardList
      break
    }
  }

  for (const source of dataSources) {
    if (source?.indicator) {
      indicator = source.indicator
      break
    }
  }

  for (const source of dataSources) {
    if (source?.alias) {
      alias = indicator ? source.alias ?? [] : []
      break
    }
  }

  if (homeData) {
    creditCardList = homeData.tokenList
    alias = homeData.alias
    indicator = homeData.indicator
  }

  if (creditCardList) {
    for (let i = 0; i < creditCardList.length; i++) {
      creditCardList[i].position = i + 1
    }
  }

  if (alias) {
    for (let i = 0; i < alias.length; i++) {
      alias[i].position = i + 1
    }
  }

  const [showAllAccounts, setShowAllAccounts] = useState(false)
  const combinedData = [...creditCardList, ...alias]
  const sortedData = combinedData.toSorted((a, b) => b.lastUse - a.lastUse)
  const visibleAccounts = showAllAccounts ? sortedData : sortedData.slice(0, 4)

  const isAnyDataPresent = () => {
    return (
      pagaTuPrestamoData ||
      cbkUssdData ||
      homeData ||
      callMeData ||
      recurrenceData ||
      sendARechargeData
    )
  }

  useEffect(() => {
    const metricsData = getMetricsData()
    if (metricsData) {
      if (isAnyDataPresent()) {
        handleSendMetrics(metricsData)
      }
    }
  }, [code, homeData])

  const getMetricsData = () => {
    if (creditCardList.length === 0 && alias.length === 0) {
      setIsVisibleAddNewPaymentMethod(true)
      if (!indicator) {
        setIsVisibleSectionAddNewCard(true)
        if (isAnyDataPresent()) {
          return {
            metricType: 'CheckOut_',
            metricName: `NuevaTarjeta-${creditCardList.length + 1}`,
          }
        }
      } else {
        setIsVisibleAddNewAccountBi(true)
        if (isAnyDataPresent()) {
          return {
            metricType: 'CheckOut_',
            metricName: `NuevaCuenta-${alias.length + 1}`,
          }
        }
      }
    } else if (creditCardList.length === 1 && alias.length === 0) {
      setIsVisibleAddNewPaymentMethod(false)
      setIsVisibleSectionAddNewCard(false)
      setIsVisibleAddNewAccountBi(false)
      setSelectedSavedCard(creditCardList[0])
      if (isAnyDataPresent()) {
        return {
          metricType: 'CheckOut_',
          metricName: `PagarTC-${creditCardList.length}`,
        }
      }
    } else if (alias.length === 1 && indicator && creditCardList.length <= 1) {
      setIsVisibleAddNewPaymentMethod(false)
      setIsVisibleSectionAddNewCard(false)
      setIsVisibleAddNewAccountBi(false)
      setSelectAccountBi(alias[0].alias)
      if (isAnyDataPresent()) {
        return {
          metricType: 'CheckOut_',
          metricName: `PagarBanco-${alias.length}`,
        }
      }
    } else {
      setIsVisibleAddNewPaymentMethod(false)
      setIsVisibleSectionAddNewCard(false)
      setIsVisibleAddNewAccountBi(false)
    }
    return null
  }

  const handleSendMetrics = metricsData => {
    if (homeData || code) {
      sendMetrics(metricsData)
    }
  }

  const toggleShowAllAccounts = () => {
    if (!showAllAccounts && isAnyDataPresent()) {
      const metricsData = {
        metricType: 'CheckOut_',
        metricName: 'VerTusOpcionesdePago',
      }
      sendMetrics(metricsData)
    }
    setShowAllAccounts(!showAllAccounts)
  }

  const resert = () => {
    setSelectedSavedCard({})
    setSelectAccountBi(null)
    setMonth('')
    setYear('')
    setSaveCard(false)
    setMonthNewCard('')
    setYearNewCard('')
    setCvv('')
    setNumberCard('')
    setSaveNewCard(true)
  }

  const handleAddNewCard = () => {
    if (!isVisibleSectionAddNewCard && isAnyDataPresent()) {
      const metricsData = {
        metricType: 'CheckOut_',
        metricName: `NuevaTarjeta-${creditCardList.length + 1}`,
      }
      sendMetrics(metricsData)
    }
    setIsVisibleSectionAddNewCard(!isVisibleSectionAddNewCard)
    setIsVisibleAddNewAccountBi(false)
    resert()
  }

  const handleAddNewPaymentMethod = () => {
    if (!isVisibleAddNewPaymentMethod && isAnyDataPresent()) {
      const metricsData = {
        metricType: 'CheckOut_',
        metricName: 'MasOpcionesPago',
      }
      sendMetrics(metricsData)
    }
    setIsVisibleAddNewPaymentMethod(!isVisibleAddNewPaymentMethod)
    setIsVisibleSectionAddNewCard(false)
    setIsVisibleAddNewAccountBi(false)
    resert()
  }

  const handleAddNewAccountBi = () => {
    if (!iIsVisibleAddNewAccountBi && isAnyDataPresent()) {
      const metricsData = {
        metricType: 'CheckOut_',
        metricName: `NuevaCuenta-${alias.length + 1}`,
      }
      sendMetrics(metricsData)
    }
    setIsVisibleAddNewAccountBi(!iIsVisibleAddNewAccountBi)
    setIsVisibleSectionAddNewCard(false)
    resert()
  }

  const handleSelectAccountBi = accountBi => {
    setIsVisibleAddNewPaymentMethod(false)
    setIsVisibleSectionAddNewCard(false)
    setIsVisibleAddNewAccountBi(false)
    resert()
    if (selectAccountBi && selectAccountBi === accountBi.alias) {
      setSelectAccountBi(null)
    } else {
      if (isAnyDataPresent()) {
        const metricsData = {
          metricType: 'CheckOut_',
          metricName: `PagarBanco-${accountBi.position}`,
        }
        sendMetrics(metricsData)
      }
      setSelectAccountBi(accountBi.alias)
    }
  }

  useEffect(() => {
    if (numberCard !== '' && numberCard.length >= 1) {
      let firstNumber = numberCard.charAt(0)
      if (firstNumber === '3') {
        setMaxLengthCvv(4)
        setMaxLengthNumberCard(15)
      } else {
        setMaxLengthCvv(3)
        setMaxLengthNumberCard(16)
      }
    }
  }, [numberCard])

  return (
    <div className="claro__container__payment__optionsWithBi">
      <div className="claro__container__payment__optionsWithBi__title">
        {PAYMENT_TITLE}
      </div>

      {visibleAccounts !== null && (
        <>
          {visibleAccounts.map(item => {
            if (item.tokenId) {
              const isVisible = isVisibleSectionAddNewCard
                ? false
                : item.tokenId === selectedSavedCard.tokenId

              return (
                <SavedCard
                  key={item.tokenId}
                  isVisible={isVisible}
                  creditCard={item}
                  sendMetrics={isAnyDataPresent() ? sendMetrics : undefined}
                />
              )
            } else {
              const isSelected = selectAccountBi === item.alias
              return (
                <SavedAccount
                  key={item.alias}
                  item={item}
                  isSelected={isSelected}
                  handleSelectAccountBi={handleSelectAccountBi}
                />
              )
            }
          })}
        </>
      )}

      {combinedData.length > 4 && showAllAccounts === false && (
        <div
          className="claro__container__payment__optionsWithBi__viewOther"
          onClick={toggleShowAllAccounts}
        >
          <img
            src={`${showAllAccounts ? circleAccepted : circleNotAccepted}`}
            alt={VIEW_OTHER_PAYMENT_OPTIONS}
          />
          {VIEW_OTHER_PAYMENT_OPTIONS}
          <img
            className="claro__container__payment__optionsWithBi__arrow"
            src={arrow}
            alt="arrow"
          />
        </div>
      )}

      {(creditCardList.length > 0 || alias.length > 0) &&
        (creditCardList.length < 3 || (alias.length < 3 && indicator)) && (
          <div
            className="claro__container__payment__optionsWithBi__newMethod"
            onClick={handleAddNewPaymentMethod}
          >
            <img
              src={`${
                isVisibleAddNewPaymentMethod
                  ? circleAccepted
                  : circleNotAccepted
              }`}
              alt={NEWPAYMENTMETHOD}
            />
            {NEWPAYMENTMETHOD}
            <img
              className="claro__container__payment__optionsWithBi__arrow"
              src={arrow}
              alt="arrow"
            />
          </div>
        )}

      {alias.length < 3 && isVisibleAddNewPaymentMethod && indicator && (
        <div
          onClick={handleAddNewAccountBi}
          className="claro__container__payment__optionsWithBi__newAccount"
        >
          <img
            src={iIsVisibleAddNewAccountBi ? circleAccepted : circleNotAccepted}
            alt={BANK_ACCOUNT}
          />
          {BANK_ACCOUNT}
          <div className="claro__container__payment__optionsWithBi__newAccount__logo">
            <img src={logoBi} alt="logoBi" />
          </div>
        </div>
      )}

      {creditCardList.length < 3 && isVisibleAddNewPaymentMethod && (
        <div
          className="claro__container__payment__optionsWithBi__newCard"
          onClick={handleAddNewCard}
        >
          <img
            src={`${
              isVisibleSectionAddNewCard ? circleAccepted : circleNotAccepted
            }`}
            alt={ADDNEWCARD}
          />
          {ADDNEWCARD}
          <img
            className="claro__container__payment__optionsWithBi__arrow"
            src={arrow}
            alt="arrow"
          />
        </div>
      )}

      {creditCardList.length < 3 && isVisibleSectionAddNewCard && (
        <NewCard
          creditCardList={creditCardList}
          sendMetrics={isAnyDataPresent() ? sendMetrics : undefined}
          optionType="claroPayPaymentAccountBi"
        />
      )}
    </div>
  )
}

export default ClaroPayPaymentAccountBi
