import React, { useEffect, useRef } from 'react'

import localRoutes from '../../constants/routes'

import recurrenceBannerImage from '../../assets/img/authMsisdn.svg'

const RecurrenceBanner = packRecurrence => {
  const {
    routesClaroPay: { claroRecurrence },
  } = localRoutes
  const subtitle =
    packRecurrence.packRecurrence.productInArr?.subtitle || undefined
  const validity =
    packRecurrence.packRecurrence.productInArr?.validity || undefined
  const code = packRecurrence.packRecurrence.code || undefined

  const bannerRef = useRef(null)

  const handleRedirect = () => {
    window.location.href = `${claroRecurrence}?code=${code}`
  }

  useEffect(() => {
    const banner = bannerRef.current

    if (banner) {
      banner.classList.add('animate-jump')
      const timer = setTimeout(() => {
        banner.classList.remove('animate-jump')
      }, 3000)

      return () => clearTimeout(timer)
    }
  }, [])

  return (
    <>
      {subtitle && validity && code && (
        <div
          ref={bannerRef}
          onClick={handleRedirect}
          className="claro__container__recurrenceBanner"
        >
          <div className="claro__container__recurrenceBanner__icon">
            <img src={recurrenceBannerImage} alt="recurrenceBannerImage" />
          </div>
          <div className="claro__container__recurrenceBanner__text">
            <div className="claro__container__recurrenceBanner__text__title">
              Tu paquete {subtitle} de {validity} - Vence HOY
            </div>
            <div className="claro__container__recurrenceBanner__text__redirect">
              Compra Aquí nuevamente
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default RecurrenceBanner
