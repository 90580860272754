import React, { useEffect, useState, useMemo } from 'react'

import { useLocation } from 'react-router-dom'

import { getAxiosService } from '../../services/getAxiosService'
import { postAxiosService } from '../../services/postAxiosService'
import {
  clearLocalStorage,
  getLocalStorage,
  setLocalStorage,
} from '../../services/storage'

import ClaroPayContext from '../../context/ClaroPayContext'

import { encryptData } from '../../components/EncryptData'
import { getImage } from '../../components/GetImage'
import { DeepLinkBi } from '../../components/DeepLinkBi'
import handleRedirectHome from '../../components/HandleRedirectHome'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import Login from '../../components/Login'
import Menu from '../../components/Menu'
import { clearStorageLandings } from '../../components/ClearStorageLandings'
import ProcessPaymentResponse from '../../components/ProcessPaymentResponse'

import BACK_END_ROUTES from '../../constants/api_backend_url_supplier'
import { RESPONSE_CODE } from '../../constants/responseCode'
import elementNameCodeMap from '../../constants/Metrics'
import localRoutes from '../../constants/routes'
import {
  PRODUCT_SELECTED_HOME,
  MENU_REDIRECT,
  MENU_CATEGORY,
  PAYMENT_PROCESS,
  MSISDN_MINECO,
  AUTH_TOKENS_MINECO,
  MENU_OPTION_SUPERPACKS,
  MENU_OPTION_RECARGAS,
  MENU_OPTION_SUPERPACKS_AND_RECARGAS,
  MENU_OPTION_ADM,
  MENU_OPTION_PAGARDEUDA,
  MENU_OPTION_ENVIARECARGA,
  MENU_TYPE_VERTICAL,
  MENU_OPTION_CERRARSESION,
} from '../../constants/ClaroPayConstants'

import authMsisdn from '../../assets/img/authMsisdn.svg'

const ClaroMinecoLayout = ({ children }) => {
  const { processPaymentResponse } = ProcessPaymentResponse()

  const {
    routesClaroPay: { claroTyC, claroPrivacity, claroHome, claroBecasMineco },
  } = localRoutes

  const location = useLocation()

  const isPublicRoute =
    location.pathname === claroTyC || location.pathname === claroPrivacity

  const {
    OK,
    OTRO_OPERADOR,
    NO_PREPAGO,
    ANTIFRAUDE,
    ANTIFRAUDE_KO,
    BLACKLIST,
    TIMEOUT,
    ERROR_INTENTOS,
    CODIGO_NO_VALIDO,
    CODIGO_EXPIRADO,
    REENVIO_SMS,
    ERROR_CODE,
    TOO_MANY_REQUESTS,
    FAIL_SERVER_CLARO_PAY,
  } = RESPONSE_CODE

  const [valueMsisdn, setValueMsisdn] = useState('')
  const [valueMsisdnValidated, setValueMsisdnValidated] = useState()
  const [errorMessageMsisdn, setErrorMessageMsisdn] = useState('')
  const [responseDescriptionMsisdn, setResponseDescriptionMsisdn] = useState()
  const [
    responseDescriptionMsisdnResend,
    setResponseDescriptionMsisdnResend,
  ] = useState()
  const [valueCode, setValueCode] = useState('')
  const [errorMessageCode, setErrorMessageCode] = useState('')
  const [isButtonEnabledMsisdn, setIsButtonEnabledMsisdn] = useState(false)
  const [isButtonEnabledCode, setIsButtonEnabledCode] = useState(false)
  const [responseDescriptionCode, setResponseDescriptionCode] = useState()
  const [minecoBecasData, setMinecoBecasData] = useState(null)
  const [minecoBecasPackSelected, setMinecoBecasPackSelected] = useState(null)
  const [selectedSavedCard, setSelectedSavedCard] = useState({})
  const [
    isAcceptedTermsAndConditions,
    setIsAcceptedTermsAndConditions,
  ] = useState(true)
  const [isVisibleSectionAddNewCard, setIsVisibleSectionAddNewCard] = useState(
    false,
  )
  const [
    isVisibleAddNewPaymentMethod,
    setIsVisibleAddNewPaymentMethod,
  ] = useState(false)
  const [iIsVisibleAddNewAccountBi, setIsVisibleAddNewAccountBi] = useState(
    false,
  )
  const [selectAccountBi, setSelectAccountBi] = useState(null)
  const [saveCard, setSaveCard] = useState(false)
  const [year, setYear] = useState('')
  const [month, setMonth] = useState('')
  const [saveNewCard, setSaveNewCard] = useState(true)
  const [yearNewCard, setYearNewCard] = useState('')
  const [monthNewCard, setMonthNewCard] = useState('')
  const [cvv, setCvv] = useState('')
  const [numberCard, setNumberCard] = useState('')
  const [maxLengthCvv, setMaxLengthCvv] = useState(3)
  const [maxLengthNumberCard, setMaxLengthNumberCard] = useState(16)
  const [processingPayment, setProcessingPayment] = useState(false)
  const [responseService, setResponseService] = useState(null)
  const [responseServiceBilling, setResponseServiceBilling] = useState(null)
  const [processingBilling, setProcessingBilling] = useState(false)
  const [isEnabledPayWithSavedCard, setIsEnabledPayWithSavedCard] = useState(
    false,
  )
  const [isEnabledPayWithNewCard, setIsEnabledPayWithNewCard] = useState(false)
  const [requestId, setRequestId] = useState(null)
  const [urlAccountBi, setUrlAccountBi] = useState('')
  const [urltiendaApps, setUrltiendaApps] = useState('')
  const [canClickButton, setCanClickButton] = useState(true)
  const [menuVisible, setMenuVisible] = useState(false)
  const [opcionSeleccionada, setOpcionSeleccionada] = useState(null)
  const [codeBilling, setCodeBilling] = useState(null)

  const authGuard = () => {
    return (
      getLocalStorage(AUTH_TOKENS_MINECO) !== undefined &&
      getLocalStorage(AUTH_TOKENS_MINECO) !== null
    )
  }

  useEffect(() => {
    setOpcionSeleccionada(null)
    if (!authGuard) {
      clearLocalStorage(MSISDN_MINECO)
    }
  }, [authGuard])

  const msisdnMineco = getLocalStorage(MSISDN_MINECO)

  const isValidatedFieldsToPayWithNewCard = () =>
    yearNewCard !== '' &&
    Number(yearNewCard) > 0 &&
    yearNewCard.length === 2 &&
    monthNewCard !== '' &&
    Number(monthNewCard) > 0 &&
    cvv !== '' &&
    Number(cvv) > 0 &&
    cvv.length === maxLengthCvv &&
    numberCard !== '' &&
    Number(numberCard) > 0 &&
    numberCard.length === maxLengthNumberCard &&
    isAcceptedTermsAndConditions === true

  const isValidatedFieldsToPayWithSavedCard = () =>
    year !== '' &&
    Number(year) > 0 &&
    year.length === 2 &&
    month !== '' &&
    Number(month) > 0 &&
    isAcceptedTermsAndConditions === true

  useEffect(() => {
    setIsEnabledPayWithSavedCard(isValidatedFieldsToPayWithSavedCard())
  }, [year, month, isAcceptedTermsAndConditions])

  useEffect(() => {
    setIsEnabledPayWithNewCard(isValidatedFieldsToPayWithNewCard())
  }, [yearNewCard, monthNewCard, cvv, numberCard, isAcceptedTermsAndConditions])

  const clearData = () => {
    setSaveCard(false)
    setYear('')
    setMonth('')
    setSaveNewCard(true)
    setYearNewCard('')
    setMonthNewCard('')
    setCvv('')
    setNumberCard('')
    setSelectedSavedCard({})
    setIsVisibleSectionAddNewCard(false)
    setIsVisibleAddNewPaymentMethod(false)
    setIsVisibleAddNewAccountBi(false)
    setSelectAccountBi(null)
  }

  const getHeader = () => {
    return {
      headers: {
        Authorization: `Bearer ${getLocalStorage(AUTH_TOKENS_MINECO)}`,
      },
    }
  }

  const getMinecoBecasData = async () => {
    clearStorageLandings()
    try {
      const header = getHeader()
      const url = BACK_END_ROUTES.minecoData
      return getAxiosService(url, header)
    } catch (error) {
      exit()
    }
  }

  const handleRedirect = () => {
    return claroBecasMineco
  }

  const responseErrorDefault = async () => {
    const response = { responseCode: FAIL_SERVER_CLARO_PAY.code }
    return await processPaymentResponse(
      response,
      handleRedirect,
      clearData,
      setCodeBilling,
    )
  }

  const payWithSavedCardMineco = async (productId, uuid) => {
    const expirationDateSavedEncrypted = await encryptData(
      `20${year}-${month.padStart(2, '0')}`,
    )
    try {
      const data = {
        productId: productId,
        conditionsAccepted: isAcceptedTermsAndConditions,
        save: saveCard,
        creditCard: {
          token: selectedSavedCard.tokenId,
          expirationDate: expirationDateSavedEncrypted,
        },
        transactionId: uuid,
      }
      const url = BACK_END_ROUTES.minecoPaymentWithSavedCard
      const header = getHeader()
      const response = await postAxiosService(url, data, header)
      return await processPaymentResponse(
        response,
        handleRedirect,
        clearData,
        setCodeBilling,
      )
    } catch (error) {
      return await responseErrorDefault()
    }
  }

  const payWithNewCardMineco = async (productId, uuid) => {
    const numberCardEncrypted = await encryptData(numberCard)
    const cvvEncrypted = await encryptData(cvv)
    const expirationDateEncrypted = await encryptData(
      `20${yearNewCard}-${monthNewCard.padStart(2, '0')}`,
    )
    try {
      const data = {
        productId: productId,
        conditionsAccepted: isAcceptedTermsAndConditions,
        save: saveNewCard,
        creditCard: {
          number: numberCardEncrypted,
          cvv: cvvEncrypted,
          expirationDate: expirationDateEncrypted,
        },
        transactionId: uuid,
      }
      const url = BACK_END_ROUTES.minecoPaymentWithNewCard
      const header = getHeader()
      const response = await postAxiosService(url, data, header)
      return await processPaymentResponse(
        response,
        handleRedirect,
        clearData,
        setCodeBilling,
      )
    } catch (error) {
      return await responseErrorDefault()
    }
  }

  const payWithAccountBiMineco = async (productId, uuid) => {
    try {
      const data = {
        productId: productId,
        account: selectAccountBi,
        conditionsAccepted: isAcceptedTermsAndConditions,
        transactionId: uuid,
      }
      const url = BACK_END_ROUTES.minecoPaymentWithAccountBi
      const header = getHeader()
      const response = await postAxiosService(url, data, header)
      return await processPaymentResponse(
        response,
        handleRedirect,
        clearData,
        setCodeBilling,
      )
    } catch (error) {
      return await responseErrorDefault()
    }
  }

  const payWithNewAccountBi = async productId => {
    setLocalStorage(PAYMENT_PROCESS, handleRedirect())
    try {
      let urlBackend = BACK_END_ROUTES.minecoUuidAccountBi
      let urlBackendCompleta = `${urlBackend}?productId=${productId}`
      const header = getHeader()
      const response = await getAxiosService(urlBackendCompleta, header)
      const { responseContent } = response
      const responseRequestId = `requestId=${responseContent}`
      setRequestId(responseRequestId)
      const { deeplink, appStoreUrl } = DeepLinkBi(responseRequestId)
      setUrlAccountBi(deeplink)
      setUrltiendaApps(appStoreUrl)
    } catch (error) {
      clearData()
      handleRedirectHome()
    }
  }

  const processConfirmMsisdnResponse = async response => {
    switch (response.responseCode) {
      case OK.code:
        setValueMsisdnValidated(response.responseContent.msisdn)
        setLocalStorage(MSISDN_MINECO, response.responseContent.msisdn)
        setResponseDescriptionMsisdn(null)
        return OK

      case OTRO_OPERADOR.code:
        setResponseDescriptionMsisdn(OTRO_OPERADOR.description)
        return OTRO_OPERADOR

      case NO_PREPAGO.code:
        setResponseDescriptionMsisdn(NO_PREPAGO.description)
        return NO_PREPAGO

      case ANTIFRAUDE.code:
        setResponseDescriptionMsisdn(ANTIFRAUDE.description)
        return ANTIFRAUDE

      case ANTIFRAUDE_KO.code:
        setResponseDescriptionMsisdn(ANTIFRAUDE_KO.description)
        return ANTIFRAUDE_KO

      case BLACKLIST.code:
        setResponseDescriptionMsisdn(BLACKLIST.description)
        return BLACKLIST

      case TIMEOUT.code:
        setResponseDescriptionMsisdn(TIMEOUT.description)
        return TIMEOUT

      case ERROR_CODE.code:
        setResponseDescriptionMsisdn(ERROR_CODE.description)
        return ERROR_CODE

      case ERROR_INTENTOS.code:
        setResponseDescriptionMsisdn(ERROR_INTENTOS.description)
        return ERROR_INTENTOS

      case FAIL_SERVER_CLARO_PAY.code:
        setResponseDescriptionMsisdn(FAIL_SERVER_CLARO_PAY.description)
        return FAIL_SERVER_CLARO_PAY

      default:
        setResponseDescriptionMsisdn(TOO_MANY_REQUESTS.description)
        return TOO_MANY_REQUESTS
    }
  }

  const confirmMsisdn = async () => {
    try {
      setIsButtonEnabledMsisdn(false)
      const data = {
        msisdn: valueMsisdn,
      }
      const url = BACK_END_ROUTES.minecoMsisdnValidate
      const response = await postAxiosService(url, data)
      return await processConfirmMsisdnResponse(response)
    } catch (error) {
      return await processConfirmMsisdnResponse({
        responseCode: FAIL_SERVER_CLARO_PAY.code,
      })
    }
  }

  const fixPhoneNumber = () => {
    setValueMsisdnValidated(null)
    setValueCode('')
    setValueMsisdn('')

    setErrorMessageMsisdn('')
    setErrorMessageCode('')
    setResponseDescriptionMsisdn()
    setResponseDescriptionMsisdnResend()
    setResponseDescriptionCode()
    setIsButtonEnabledCode(false)
  }

  const processAuthResendCodeResponse = async response => {
    switch (response.responseCode) {
      case REENVIO_SMS.code:
        setResponseDescriptionMsisdnResend(REENVIO_SMS.description)
        return REENVIO_SMS

      case OTRO_OPERADOR.code:
        setResponseDescriptionMsisdnResend(OTRO_OPERADOR.description)
        return OTRO_OPERADOR

      case NO_PREPAGO.code:
        setResponseDescriptionMsisdnResend(NO_PREPAGO.description)
        return NO_PREPAGO

      case ANTIFRAUDE.code:
        setResponseDescriptionMsisdnResend(ANTIFRAUDE.description)
        return ANTIFRAUDE

      case ANTIFRAUDE_KO.code:
        setResponseDescriptionMsisdnResend(ANTIFRAUDE_KO.description)
        return ANTIFRAUDE_KO

      case BLACKLIST.code:
        setResponseDescriptionMsisdnResend(BLACKLIST.description)
        return BLACKLIST

      case TIMEOUT.code:
        setResponseDescriptionMsisdnResend(TIMEOUT.description)
        return TIMEOUT

      case ERROR_CODE.code:
        setResponseDescriptionMsisdnResend(ERROR_CODE.description)
        return ERROR_CODE

      case ERROR_INTENTOS.code:
        setResponseDescriptionMsisdnResend(ERROR_INTENTOS.description)
        return ERROR_INTENTOS

      case FAIL_SERVER_CLARO_PAY.code:
        setResponseDescriptionMsisdnResend(FAIL_SERVER_CLARO_PAY.description)
        return FAIL_SERVER_CLARO_PAY

      default:
        setResponseDescriptionMsisdnResend(TOO_MANY_REQUESTS.description)
        return TOO_MANY_REQUESTS
    }
  }

  const resendCode = async () => {
    try {
      const data = {
        msisdn: valueMsisdnValidated,
      }
      const url = BACK_END_ROUTES.minecoMsisdnValidate
      const response = await postAxiosService(url, data)
      return await processAuthResendCodeResponse(response)
    } catch (error) {
      return await processAuthResendCodeResponse({
        responseCode: FAIL_SERVER_CLARO_PAY.code,
      })
    }
  }

  const processAuthCodeResponse = async response => {
    switch (response.responseCode) {
      case OK.code:
        setLocalStorage(
          AUTH_TOKENS_MINECO,
          response.responseContent.accessToken,
        )
        setResponseDescriptionMsisdn('')
        return OK

      case CODIGO_NO_VALIDO.code:
        setResponseDescriptionCode(CODIGO_NO_VALIDO.description)
        return CODIGO_NO_VALIDO

      case CODIGO_EXPIRADO.code:
        setResponseDescriptionCode(CODIGO_EXPIRADO.description)
        return CODIGO_EXPIRADO

      case TIMEOUT.code:
        setResponseDescriptionCode(TIMEOUT.description)
        return TIMEOUT

      case ERROR_INTENTOS.code:
        setResponseDescriptionCode(ERROR_INTENTOS.description)
        return ERROR_INTENTOS

      default:
        setResponseDescriptionCode(TOO_MANY_REQUESTS.description)
        return TOO_MANY_REQUESTS
    }
  }

  const confirmCode = async () => {
    try {
      setIsButtonEnabledCode(false)
      const data = {
        msisdn: valueMsisdnValidated,
        code: valueCode,
      }
      const url = BACK_END_ROUTES.minecoCodeValidate
      const response = await postAxiosService(url, data)
      return await processAuthCodeResponse(response)
    } catch (error) {
      return await processAuthCodeResponse({
        responseCode: FAIL_SERVER_CLARO_PAY.code,
      })
    }
  }

  const handleChangeMsisdn = e => {
    let numericValue = e.target.value.replace(/\D/g, '')
    numericValue = numericValue.slice(0, 8)
    setValueMsisdn(numericValue)

    if (numericValue.length !== 8) {
      setErrorMessageMsisdn('El número debe tener 8 dígitos')
    } else {
      setErrorMessageMsisdn('')
    }
    setResponseDescriptionMsisdn(null)
    setIsButtonEnabledMsisdn(numericValue.length === 8)
  }

  const handleChangeCode = e => {
    let numericValue = e.target.value.replace(/\D/g, '')
    numericValue = numericValue.slice(0, 6)
    setValueCode(numericValue)

    if (numericValue.length !== 6) {
      setErrorMessageCode('El código debe tener 6 dígitos')
    } else {
      setErrorMessageCode('')
    }
    setResponseDescriptionCode(null)
    setResponseDescriptionMsisdnResend(null)
    setIsButtonEnabledCode(numericValue.length === 6)
  }

  const exit = () => {
    clearStorageLandings()
    clearLocalStorage(AUTH_TOKENS_MINECO)
    clearLocalStorage(MSISDN_MINECO)
    window.location.href = window.location
  }

  const sendMetrics = async metricsData => {
    const msisdnMetricsLocalStorage = getLocalStorage(MSISDN_MINECO)
    let msisdnMetrics = msisdnMetricsLocalStorage ?? '-'
    let elementLocationMetrics = 'HOME'
    let elementLocationCodeMetrics = 'HOM'
    let elementNameMetrics = ''
    const { metricName } = metricsData
    elementNameMetrics = metricName

    const elementNameCodeMetrics =
      elementNameCodeMap[elementNameMetrics] || 'CódigoNoEncontrado'
    try {
      const data = {
        elementLocation: elementLocationMetrics,
        elementLocationCode: elementLocationCodeMetrics,
        elementName: elementNameMetrics,
        elementNameCode: elementNameCodeMetrics,
        msisdn: msisdnMetrics,
        code: 'Mineco',
      }
      const url = BACK_END_ROUTES.registerMetrics
      await postAxiosService(url, data)
    } catch (error) {}
  }

  const toggleMenu = () => {
    if (!menuVisible) {
      const metricsData = {
        metricType: 'Menu',
        metricName: 'Menu_DespliegueMenuPrincipal',
      }
      sendMetrics(metricsData)
    }
    setMenuVisible(!menuVisible)
  }

  const getMetricsData = (type, opcion) => ({
    metricType: 'Menu',
    metricName:
      type === MENU_TYPE_VERTICAL ? `Menu_${opcion}` : `CintaGris_${opcion}`,
  })

  const redirectMenu = async menuType => {
    setLocalStorage(MENU_REDIRECT, menuType)
    window.location.href = window.location.origin
  }

  const handleOptionClick = async (opcion, type) => {
    if (opcionSeleccionada !== opcion) {
      if (opcion !== MENU_OPTION_CERRARSESION) {
        setLocalStorage(MENU_CATEGORY, opcion)
        setOpcionSeleccionada(opcion)
      }

      const metricsData = getMetricsData(type, opcion)
      await sendMetrics(metricsData)
      clearLocalStorage(PRODUCT_SELECTED_HOME)
      clearLocalStorage(MENU_REDIRECT)

      const actionMap = {
        [MENU_OPTION_SUPERPACKS]: claroHome,
        [MENU_OPTION_RECARGAS]: claroHome,
        [MENU_OPTION_SUPERPACKS_AND_RECARGAS]: claroHome,
        [MENU_OPTION_ADM]: () => redirectMenu(MENU_OPTION_ADM),
        [MENU_OPTION_PAGARDEUDA]: () => redirectMenu(MENU_OPTION_PAGARDEUDA),
        [MENU_OPTION_ENVIARECARGA]: () =>
          redirectMenu(MENU_OPTION_ENVIARECARGA),
        [MENU_OPTION_CERRARSESION]: exit,
      }

      const action = actionMap[opcion]
      if (typeof action === 'function') {
        action()
      } else {
        window.location.href = `${window.location.origin}${action}`
      }
    }
  }

  const msisdnHeader =
    minecoBecasData && msisdnMineco ? msisdnMineco.slice(3) : ''

  const isAuthenticated = authGuard()

  const dependencies = {
    isAcceptedTermsAndConditions,
    setIsAcceptedTermsAndConditions,
    selectedSavedCard,
    setSelectedSavedCard,
    setSaveCard,
    year,
    setYear,
    month,
    setMonth,
    saveNewCard,
    setSaveNewCard,
    yearNewCard,
    setYearNewCard,
    monthNewCard,
    setMonthNewCard,
    cvv,
    setCvv,
    numberCard,
    setNumberCard,
    processingPayment,
    setProcessingPayment,
    responseService,
    setResponseService,
    isVisibleSectionAddNewCard,
    setIsVisibleSectionAddNewCard,
    isVisibleAddNewPaymentMethod,
    setIsVisibleAddNewPaymentMethod,
    iIsVisibleAddNewAccountBi,
    setIsVisibleAddNewAccountBi,
    selectAccountBi,
    setSelectAccountBi,
    maxLengthCvv,
    setMaxLengthCvv,
    maxLengthNumberCard,
    setMaxLengthNumberCard,
    isValidatedFieldsToPayWithSavedCard,
    isValidatedFieldsToPayWithNewCard,
    isEnabledPayWithSavedCard,
    isEnabledPayWithNewCard,
    setIsEnabledPayWithSavedCard,
    minecoBecasData,
    setMinecoBecasData,
    getHeader,
    getMinecoBecasData,
    payWithSavedCardMineco,
    payWithNewCardMineco,
    minecoBecasPackSelected,
    setMinecoBecasPackSelected,
    payWithNewAccountBi,
    payWithAccountBiMineco,
    requestId,
    setRequestId,
    urlAccountBi,
    setUrlAccountBi,
    urltiendaApps,
    setUrltiendaApps,
    canClickButton,
    setCanClickButton,
    getImage,
    responseServiceBilling,
    setResponseServiceBilling,
    processingBilling,
    setProcessingBilling,
    codeBilling,
    setCodeBilling,
  }

  const contextValue = useMemo(
    () => ({
      ...dependencies,
    }),
    Object.values(dependencies),
  )

  return (
    <ClaroPayContext.Provider value={contextValue}>
      <div className="claro">
        <Header mssidn={msisdnHeader} toggleMenu={toggleMenu} />

        <Menu
          opcionSeleccionada={opcionSeleccionada}
          toggleMenu={toggleMenu}
          handleOptionClick={handleOptionClick}
          isAuthenticated={isAuthenticated}
          menuVisible={menuVisible}
        />

        {isAuthenticated || isPublicRoute ? (
          <div className="claro__container">{children}</div>
        ) : (
          <div className="claro__container">
            <Login
              authMsisdn={authMsisdn}
              valueMsisdn={valueMsisdn}
              valueMsisdnValidated={valueMsisdnValidated}
              valueCode={valueCode}
              handleChangeMsisdn={handleChangeMsisdn}
              handleChangeCode={handleChangeCode}
              confirmMsisdn={confirmMsisdn}
              fixPhoneNumber={fixPhoneNumber}
              resendCode={resendCode}
              confirmCode={confirmCode}
              isButtonEnabledMsisdn={isButtonEnabledMsisdn}
              isButtonEnabledCode={isButtonEnabledCode}
              errorMessageMsisdn={errorMessageMsisdn}
              errorMessageCode={errorMessageCode}
              responseDescriptionMsisdn={responseDescriptionMsisdn}
              setResponseDescriptionMsisdn={setResponseDescriptionMsisdn}
              responseDescriptionCode={responseDescriptionCode}
              setResponseDescriptionCode={setResponseDescriptionCode}
              responseDescriptionMsisdnResend={responseDescriptionMsisdnResend}
              setResponseDescriptionMsisdnResend={
                setResponseDescriptionMsisdnResend
              }
            />
          </div>
        )}

        <Footer />
      </div>
    </ClaroPayContext.Provider>
  )
}
export default ClaroMinecoLayout
