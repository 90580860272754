import React from 'react'

import { labels } from '../../constants/ClaroPayConstants'

import claroIcon from '../../assets/img/claroIcon.png'

const Header = ({ mssidn, toggleMenu }) => {
  const { GREETINGS } = labels

  return (
    <div className="claro__header">
      <div className="claro__header__primary">
        <div className="claro__header__primary__greetings">{GREETINGS}</div>
        <div className="claro__header__primary__msisdn">{mssidn ?? mssidn}</div>
      </div>

      <div className="claro__header__secondary">
        <a href="/home" className="claro__header__secondary__claroIcon">
          <img src={claroIcon} alt="claroIcon" />
        </a>
        <div
          role="button"
          className="claro__header__secondary__menuVIcon"
          onClick={toggleMenu}
          aria-label="Menu"
        >
          <div className="claro__header__secondary__menuVIcon__line"></div>
          <div className="claro__header__secondary__menuVIcon__line"></div>
          <div className="claro__header__secondary__menuVIcon__line"></div>
        </div>
      </div>
    </div>
  )
}

export default Header
