import React, { useContext, useState } from 'react'

import CustomSwitch from '../CustomSwitch'
import { nextInput } from '../NextInput'
import { lastTwoDigitsYear, penultimateTwoDigitsYear } from '../DateYear'
import { validateIsInteger } from '../ValidateIsInteger'

import ClaroPayContext from '../../context/ClaroPayContext'

const NewCard = ({ creditCardList, sendMetrics, optionType }) => {
  const claroPayContext = useContext(ClaroPayContext)
  const {
    numberCard,
    setNumberCard,
    monthNewCard,
    setMonthNewCard,
    yearNewCard,
    setYearNewCard,
    cvv,
    setCvv,
    saveNewCard,
    setSaveNewCard,
    maxLengthNumberCard,
    setMaxLengthNumberCard,
    maxLengthCvv,
    setMaxLengthCvv,
  } = claroPayContext

  const [numberCardFormatted, setNumberCardFormatted] = useState('')

  const validateMaxLength = value => {
    const firstDigit = value.charAt(0)
    if (firstDigit === '3') {
      setMaxLengthCvv(4)
      setMaxLengthNumberCard(15)
    } else {
      setMaxLengthCvv(3)
      setMaxLengthNumberCard(16)
    }
  }

  const handleChangeCardNumber = event => {
    const number = event.target.value.replace(/\s/g, '')
    if (validateIsInteger(number)) {
      validateMaxLength(number)
      if (number.length <= maxLengthNumberCard) {
        let formattedValue
        if (maxLengthNumberCard === 16) {
          formattedValue = number.match(/.{1,4}/g)?.join('  ') || number
        } else {
          formattedValue = number
            .replace(/^(\d{4})(\d{0,6})(\d{0,5}).*/, '$1  $2  $3')
            .trim()
        }
        setNumberCardFormatted(formattedValue)
        setNumberCard(number.trim())
        setCvv('')
        if (number.length === maxLengthNumberCard) {
          nextInput(event)
        }
      }
    }
  }

  const handleChangeMonth = event => {
    const number = event.target.value
    if (validateIsInteger(number)) {
      if (number.length <= 2) {
        if (number >= 0 && number < 13) {
          setMonthNewCard(number.trim())
          if (number.length === 2) {
            nextInput(event)
          }
        }
      }
    }
  }

  const handleChangeYear = event => {
    const number = event.target.value
    if (validateIsInteger(number)) {
      if (number.length <= 2) {
        if (
          (yearNewCard === '' && number >= penultimateTwoDigitsYear) ||
          number >= lastTwoDigitsYear ||
          (yearNewCard !== '' && yearNewCard.length === 2) ||
          (yearNewCard !== '' && number === '')
        ) {
          setYearNewCard(number)
          if (number.length === 2) {
            nextInput(event)
          }
        }
      }
    }
  }

  const handleChangeCvv = event => {
    const number = event.target.value
    if (validateIsInteger(number)) {
      validateMaxLength(numberCard)
      if (number.length <= maxLengthCvv) {
        setCvv(number.trim())
      }
    }
  }

  const handleSwitchClick = value => {
    if (sendMetrics !== undefined && !value) {
      const cantCreditCards = creditCardList.length + 1
      const metricsData = {
        metricType: 'CheckOut_',
        metricName: `SaveCard-${cantCreditCards}`,
      }
      sendMetrics(metricsData)
    }
    setSaveNewCard(value)
  }

  return (
    <>
      <div
        className={`claro__container__payment__options__newCard__selected ${optionType}`}
      >
        <form className="claro__container__payment__options__newCard__selected__form">
          <div className="claro__container__payment__options__newCard__selected__form__numberCard">
            <div className="claro__container__payment__options__newCard__selected__form__numberCard__text">
              Número Tarjeta:
            </div>
            <input
              placeholder="Ingresa el número de tu tarjeta"
              maxLength={
                maxLengthNumberCard === 15
                  ? maxLengthNumberCard + 6
                  : maxLengthNumberCard + 8
              }
              type="text"
              inputMode="numeric"
              name="numberCard"
              value={numberCardFormatted}
              autoFocus
              onChange={handleChangeCardNumber}
            />
          </div>

          <div className="claro__container__payment__options__newCard__selected__form__monthAndYearAndCvc">
            <div className="claro__container__payment__options__newCard__selected__form__monthAndYearAndCvc__monthAndYear">
              <div className="claro__container__payment__options__newCard__selected__form__monthAndYearAndCvc__monthAndYear__text">
                MM / AA
              </div>
              <div className="claro__container__payment__options__newCard__selected__form__monthAndYearAndCvc__monthAndYear__inputs">
                <input
                  placeholder="00"
                  maxLength="2"
                  type="text"
                  inputMode="numeric"
                  name="month"
                  value={monthNewCard}
                  onChange={handleChangeMonth}
                />{' '}
                /{' '}
                <input
                  placeholder="00"
                  maxLength="2"
                  type="text"
                  inputMode="numeric"
                  name="year"
                  value={yearNewCard}
                  onChange={handleChangeYear}
                />
              </div>
            </div>

            <div className="claro__container__payment__options__newCard__selected__form__monthAndYearAndCvc__cvc">
              <div className="claro__container__payment__options__newCard__selected__form__cvc__text">
                CVC
              </div>
              <input
                placeholder={maxLengthCvv === 4 ? '0000' : '000'}
                maxLength={maxLengthCvv}
                type="text"
                inputMode="numeric"
                name="cvv"
                value={cvv}
                onChange={handleChangeCvv}
              />
            </div>
          </div>
        </form>
      </div>

      <div
        className={`claro__container__payment__options__newCard__selected__remember ${optionType}`}
      >
        <CustomSwitch
          isSelected={saveNewCard}
          onClick={handleSwitchClick}
          text="Guardar datos de tarjeta, para futuras compras"
        />
      </div>
    </>
  )
}

export default NewCard
