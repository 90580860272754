import React from 'react'

import {
  MENU_OPTION_SUPERPACKS,
  MENU_OPTION_RECARGAS,
  MENU_OPTION_SUPERPACKS_AND_RECARGAS,
  MENU_OPTION_ADM,
  MENU_OPTION_PAGARDEUDA,
  MENU_OPTION_ENVIARECARGA,
  MENU_OPTION_CERRARSESION,
  MENU_TYPE_VERTICAL,
  MENU_TYPE_HORIZONTAL,
  labels,
} from '../../constants/ClaroPayConstants'

import menuIconSuperpacksOrRecargas from '../../assets/img/menuIcon-Superpacks.svg'
import menuIconAdm from '../../assets/img/menuIcon-ClaroTePresta.svg'
import menuIconPagarDeuda from '../../assets/img/menuIcon-PagaTuPrestamo.svg'
import menuIconEnviaRecarga from '../../assets/img/menuIcon-EnviaUnaRecarga.svg'
import menuIconCerrarSesion from '../../assets/img/cerrarSesion.svg'

const {
  MENU_OPTION_SUPERPACKS_LABEL,
  MENU_OPTION_RECARGAS_LABEL,
  MENU_OPTION_SUPERPACKS_AND_RECARGAS_LABEL,
  MENU_OPTION_ADM_LABEL,
  MENU_OPTION_PAGARDEUDA_LABEL,
  MENU_OPTION_ENVIARECARGA_LABEL,
  MENU_OPTION_CERRARSESION_LABEL,
} = labels

const MenuOption = ({ icon, label, selected, onClick, menuType }) => (
  <div
    className={`claro__menu__${menuType}__option ${
      selected ? 'seleccionada' : ''
    }`}
    onClick={onClick}
  >
    <img src={icon} alt={label} className={`icono-${menuType}`} />
    <span>{label}</span>
  </div>
)

const Menu = ({
  opcionSeleccionada,
  toggleMenu,
  handleOptionClick,
  isAuthenticated,
  menuVisible,
}) => {
  return (
    <div className="claro__menu">
      <div
        role="button"
        className={`claro__menu__menuV ${menuVisible ? 'visible' : ''}`}
        onClick={toggleMenu}
        aria-label="Toggle Menu"
      >
        <MenuOption
          id={MENU_OPTION_ENVIARECARGA}
          icon={menuIconEnviaRecarga}
          label={MENU_OPTION_ENVIARECARGA_LABEL}
          selected={opcionSeleccionada === MENU_OPTION_ENVIARECARGA}
          onClick={() =>
            handleOptionClick(MENU_OPTION_ENVIARECARGA, MENU_TYPE_VERTICAL)
          }
          menuType={MENU_TYPE_VERTICAL}
        />
        <MenuOption
          id={MENU_OPTION_SUPERPACKS}
          icon={menuIconSuperpacksOrRecargas}
          label={MENU_OPTION_SUPERPACKS_LABEL}
          selected={opcionSeleccionada === MENU_OPTION_SUPERPACKS}
          onClick={() =>
            handleOptionClick(MENU_OPTION_SUPERPACKS, MENU_TYPE_VERTICAL)
          }
          menuType={MENU_TYPE_VERTICAL}
        />
        <MenuOption
          id={MENU_OPTION_RECARGAS}
          icon={menuIconSuperpacksOrRecargas}
          label={MENU_OPTION_RECARGAS_LABEL}
          selected={opcionSeleccionada === MENU_OPTION_RECARGAS}
          onClick={() =>
            handleOptionClick(MENU_OPTION_RECARGAS, MENU_TYPE_VERTICAL)
          }
          menuType={MENU_TYPE_VERTICAL}
        />
        <MenuOption
          id={MENU_OPTION_ADM}
          icon={menuIconAdm}
          label={MENU_OPTION_ADM_LABEL}
          selected={opcionSeleccionada === MENU_OPTION_ADM}
          onClick={() => handleOptionClick(MENU_OPTION_ADM, MENU_TYPE_VERTICAL)}
          menuType={MENU_TYPE_VERTICAL}
        />
        <MenuOption
          id={MENU_OPTION_PAGARDEUDA}
          icon={menuIconPagarDeuda}
          label={MENU_OPTION_PAGARDEUDA_LABEL}
          selected={opcionSeleccionada === MENU_OPTION_PAGARDEUDA}
          onClick={() =>
            handleOptionClick(MENU_OPTION_PAGARDEUDA, MENU_TYPE_VERTICAL)
          }
          menuType={MENU_TYPE_VERTICAL}
        />
        {isAuthenticated && (
          <MenuOption
            id={MENU_OPTION_CERRARSESION}
            icon={menuIconCerrarSesion}
            label={MENU_OPTION_CERRARSESION_LABEL}
            selected={opcionSeleccionada === MENU_OPTION_CERRARSESION}
            onClick={() =>
              handleOptionClick(MENU_OPTION_CERRARSESION, MENU_TYPE_VERTICAL)
            }
            menuType={MENU_TYPE_VERTICAL}
          />
        )}
      </div>

      <div className="claro__menu__menuH">
        <MenuOption
          id={MENU_OPTION_SUPERPACKS_AND_RECARGAS}
          icon={menuIconSuperpacksOrRecargas}
          label={MENU_OPTION_SUPERPACKS_AND_RECARGAS_LABEL}
          selected={
            opcionSeleccionada === MENU_OPTION_SUPERPACKS ||
            opcionSeleccionada === MENU_OPTION_RECARGAS ||
            opcionSeleccionada === MENU_OPTION_SUPERPACKS_AND_RECARGAS
          }
          onClick={() =>
            handleOptionClick(
              MENU_OPTION_SUPERPACKS_AND_RECARGAS,
              MENU_TYPE_HORIZONTAL,
            )
          }
          menuType={MENU_TYPE_HORIZONTAL}
        />
        <MenuOption
          id={MENU_OPTION_ADM}
          icon={menuIconAdm}
          label={MENU_OPTION_ADM_LABEL}
          selected={opcionSeleccionada === MENU_OPTION_ADM}
          onClick={() =>
            handleOptionClick(MENU_OPTION_ADM, MENU_TYPE_HORIZONTAL)
          }
          menuType={MENU_TYPE_HORIZONTAL}
        />
        <MenuOption
          id={MENU_OPTION_PAGARDEUDA}
          icon={menuIconPagarDeuda}
          label={MENU_OPTION_PAGARDEUDA_LABEL}
          selected={opcionSeleccionada === MENU_OPTION_PAGARDEUDA}
          onClick={() =>
            handleOptionClick(MENU_OPTION_PAGARDEUDA, MENU_TYPE_HORIZONTAL)
          }
          menuType={MENU_TYPE_HORIZONTAL}
        />
        <MenuOption
          id={MENU_OPTION_ENVIARECARGA}
          icon={menuIconEnviaRecarga}
          label={MENU_OPTION_ENVIARECARGA_LABEL}
          selected={opcionSeleccionada === MENU_OPTION_ENVIARECARGA}
          onClick={() =>
            handleOptionClick(MENU_OPTION_ENVIARECARGA, MENU_TYPE_HORIZONTAL)
          }
          menuType={MENU_TYPE_HORIZONTAL}
        />
      </div>
    </div>
  )
}

export default Menu
