import React, { useContext, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import ClaroPayContext from '../../context/ClaroPayContext'

import { RESPONSE_CODE } from '../../constants/responseCode'
import {
  labels,
  CODE_ADM,
  URL_KEY_TO_LOCALSTORAGE_ADM,
  AUTH_TOKENS_ADM,
  PRODUCT_AUTH_ADM,
  MSISDN_ADM,
  UUID_PROCESS_ADM,
  PROCESO_PAGO,
} from '../../constants/ClaroPayConstants'
import BACK_END_ROUTES from '../../constants/api_backend_url_supplier'
import localRoutes from '../../constants/routes'

import handleRedirectHome from '../../components/HandleRedirectHome'
import NextButton from '../../components/NextButton'

import {
  setLocalStorage,
  clearLocalStorage,
  getLocalStorage,
} from '../../services/storage'
import { postAxiosService } from '../../services/postAxiosService'

import phoneSMS from '../../assets/img/phoneSMS.svg'
import processingPaymentImage from '../../assets/img/processingPayment.svg'
import successfulPayment from '../../assets/img/successfulPayment.svg'
import happy from '../../assets/img/happy.svg'
import sad from '../../assets/img/sad.svg'

const ClaroAdmProcess = () => {
  const history = useHistory()

  const claroPayContext = useContext(ClaroPayContext)

  const {
    setSelectedPackAdm,
    selectedPackAdm,
    responseService,
    processingPayment,
    setProcessingPayment,
    setResponseService,
    setProductsAdm,
    setTokenAdm,
    setAdmData,
    sendMetrics,
    setExitFunctionAdm,
    getImage,
    formatNumero,
  } = claroPayContext

  const {
    routesClaroPay: { claroPagaTuPrestamo },
  } = localRoutes

  const {
    OK_ADM,
    UNKNOWN_ADM,
    PAGA_TU_PRESTAMO_ADM,
    CON_SALDO_ADM,
    NOT_PERMISSIONS_ADM,
    NOT_LOCATED_ANSWER_ADM,
    SESSION_UNDEFINED_ADM,
    FAIL_SERVER_CLARO_PAY,
  } = RESPONSE_CODE

  const { REMEMBER_WILL_SEND_SMS, SELECTED_ACTIVE_PACKAGE } = labels

  useEffect(() => {
    setLocalStorage(PROCESO_PAGO, true)
    setExitFunctionAdm(() => exitProcess)
  }, [])

  useEffect(() => {
    if (processingPayment && responseService !== null) {
      clearLocalStorage(PROCESO_PAGO)

      const metricsDataProcesoValidacion = {
        metricType: 'Proceso_Validación',
        metricName: 'Proceso_Validación',
      }
      sendMetrics(metricsDataProcesoValidacion)

      let metricsData = {
        metricType: 'Error_General-TimeOut',
        metricName: 'Error_General-TimeOut',
      }

      const metricsMapping = {
        [OK_ADM.code]: { metricType: 'DeudaOk', metricName: 'DeudaOk' },
        [PAGA_TU_PRESTAMO_ADM.code]: {
          metricType: 'PagoPendiente',
          metricName: 'PagoPendiente',
        },
      }

      metricsData = metricsMapping[responseService.code] || metricsData

      sendMetrics(metricsData)

      if (responseService.code === OK_ADM.code) {
        const timeoutId = setTimeout(() => {
          handleRedirectHomeSend(false)
        }, 8000)
        return () => clearTimeout(timeoutId)
      }
    }
  }, [responseService])

  const handleRedirectHomeSend = async value => {
    if (value) {
      const metricsData = {
        metricType: 'Botón_IrPáginaPrincipal',
        metricName: 'Botón_IrPáginaPrincipal',
      }
      await sendMetrics(metricsData)
    }
    clearStorage()
    handleRedirectHome()
  }

  const handleRedirectAtras = () => {
    setProcessingPayment(false)
    setResponseService(null)
    history.go(-1)
  }

  const clearStorage = () => {
    clearLocalStorage(AUTH_TOKENS_ADM)
    clearLocalStorage(PRODUCT_AUTH_ADM)
    clearLocalStorage(MSISDN_ADM)
    clearLocalStorage(CODE_ADM)
    clearLocalStorage(URL_KEY_TO_LOCALSTORAGE_ADM)
    clearLocalStorage(UUID_PROCESS_ADM)
  }

  const exitProcess = () => {
    setProcessingPayment(false)
    setResponseService(null)
    setTokenAdm({
      valid: false,
      token: '',
    })
    setAdmData([])
    setProductsAdm([])
    setSelectedPackAdm(null)
    clearStorage()
    history.go(-1)
  }

  const redirectPagaTuPrestamo = async () => {
    const metricsData = {
      metricType: 'PagarDeuda',
      metricName: 'PagarDeuda',
    }
    sendMetrics(metricsData)
    const Authorization =
      getLocalStorage(AUTH_TOKENS_ADM) !== undefined &&
      getLocalStorage(AUTH_TOKENS_ADM) !== null
        ? { Authorization: `Bearer ${getLocalStorage(AUTH_TOKENS_ADM)}` }
        : {}

    const productAuthAdm = getLocalStorage(PRODUCT_AUTH_ADM)
    let localCode = getLocalStorage(CODE_ADM)

    try {
      const data = productAuthAdm ? undefined : { code: localCode }
      let response
      if (productAuthAdm) {
        const url = BACK_END_ROUTES.admPagaTuPrestamoLogin
        const headers = { ...Authorization }
        response = await postAxiosService(url, data, { headers })
      } else {
        const url = BACK_END_ROUTES.admPagaTuPrestamoLink
        response = await postAxiosService(url, data)
      }
      const { code } = response.responseContent
      const origin = window.location.origin
      const redirectUrl = `${origin}${claroPagaTuPrestamo}?code=${code}`
      clearStorage()
      window.location.href = redirectUrl
    } catch (error) {
      clearStorage()
      handleRedirectHome()
    }
  }

  const formattedAmount = formatNumero(selectedPackAdm?.amount)

  return (
    processingPayment && (
      <div className="claro__container__process">
        {responseService !== null ? (
          <React.Fragment>
            {responseService.code === OK_ADM.code && (
              <div className="claro__container__process__sucess">
                <div className="claro__container__process__sucess__information__general">
                  <img
                    className="claro__container__process__image"
                    src={successfulPayment}
                    alt="success"
                  />
                  <p className="claro__container__process__generalMessage">
                    <span role="img" aria-label="Snowman">
                      {responseService.description}
                    </span>
                  </p>

                  {selectedPackAdm && (
                    <div className="claro__container__process__success__informations__package">
                      <div className="claro__container__process__success__informations__package__info">
                        <p>Paquete</p>
                        <p>
                          <span>{` Vigencia: ${selectedPackAdm.validity}`}</span>
                        </p>
                      </div>
                      <p className="claro__container__process__success__informations__package__amount">
                        {`${selectedPackAdm.currency}${formattedAmount}`}
                      </p>
                    </div>
                  )}
                </div>

                <div className="claro__container__process__sucess__noteSms">
                  <img src={phoneSMS} alt="phone" />{' '}
                  <p>
                    {REMEMBER_WILL_SEND_SMS}
                    <span> {SELECTED_ACTIVE_PACKAGE}.</span>
                  </p>
                </div>
              </div>
            )}

            {(responseService.code === NOT_LOCATED_ANSWER_ADM.code ||
              responseService.code === SESSION_UNDEFINED_ADM.code ||
              responseService.code === FAIL_SERVER_CLARO_PAY.code) && (
              <>
                <div className="claro__container__process__error">
                  {responseService.img !== null &&
                    responseService.img !== undefined && (
                      <img
                        className="claro__container__process__image"
                        src={getImage(responseService.img)}
                        alt="error"
                      />
                    )}

                  <p className="claro__container__process__generalMessage">
                    {responseService.description}
                  </p>
                </div>

                <NextButton
                  textBtn={responseService.title_button}
                  onClick={handleRedirectAtras}
                  enabled
                />
              </>
            )}

            {responseService.code === UNKNOWN_ADM.code && (
              <>
                <div className="claro__container__process__error">
                  <img
                    className="claro__container__process__image"
                    src={getImage(responseService.img)}
                    alt=""
                  />

                  <p className="claro__container__process__generalMessage">
                    {responseService.description}
                    <img
                      src={sad}
                      alt="Sad"
                      className="claro__container__process__error__imageEmoji"
                    />
                  </p>
                </div>

                <NextButton
                  textBtn={responseService.title_button}
                  onClick={handleRedirectAtras}
                  enabled
                />
              </>
            )}

            {responseService.code === CON_SALDO_ADM.code && (
              <>
                <div className="claro__container__process__error">
                  <img
                    className="claro__container__process__image"
                    src={getImage(responseService.img)}
                    alt=""
                  />

                  <p className="claro__container__process__generalMessage">
                    {responseService.description}{' '}
                    <img
                      src={happy}
                      alt="Happy"
                      className="claro__container__process__error__imageEmoji"
                    />
                  </p>
                </div>

                <NextButton
                  textBtn={responseService.title_button}
                  onClick={handleRedirectAtras}
                  enabled
                />
              </>
            )}

            {responseService.code === PAGA_TU_PRESTAMO_ADM.code && (
              <>
                <div className="claro__container__process__error">
                  <img
                    className="claro__container__process__image"
                    src={getImage(responseService.img)}
                    alt="error"
                  />

                  <p className="claro__container__process__generalMessage">
                    Tienes un Préstamo Pendiente. <br />
                    <br />
                    Si quieres cancelarlo + Comprar un Paquete/Recarga <br />
                    da click en Pagar
                  </p>
                </div>

                <NextButton
                  textBtn="Pagar"
                  onClick={redirectPagaTuPrestamo}
                  enabled
                />
              </>
            )}

            {responseService.code === NOT_PERMISSIONS_ADM.code && (
              <>
                <div className="claro__container__process__error">
                  <img
                    className="claro__container__process__image"
                    src={getImage(responseService.img)}
                    alt="error"
                  />

                  <p className="claro__container__process__generalMessage">
                    Servicio No Disponible. <br />
                    <br />
                    Quieres hacer una compra de Paquete o Recarga? <br />
                    da click en Continuar
                  </p>
                </div>

                <NextButton
                  textBtn={responseService.title_button}
                  onClick={() => handleRedirectHomeSend(true)}
                  enabled
                />
              </>
            )}
          </React.Fragment>
        ) : (
          <div className="claro__container__process__waiting">
            <img
              className="claro__container__process__image"
              src={processingPaymentImage}
              alt="waiting"
            />
            <p className="claro__container__process__generalMessage">
              En breve te notificaremos el estado de tu solicitud...
            </p>
          </div>
        )}
      </div>
    )
  )
}

export default ClaroAdmProcess
